:root {
    --fontFamily: 'Helvetica Neue', sans-serif;
    --fontBold: 600;
    --fontWeightSecundaryText: 400;
    --fontSize: 14px;
    --fontSizeError: 13px;
    --fontSizeSecondaryText: 12px;
    --colorYellowFontError: yellow;
    --colorBluePrimaryButton: #1976D2;
    --colorBlueAccentButton: #2196F3;
    --colorBlueDarkBackground: #00062Eff;
    --borderRadiusInput: 4px;
}

* {
    font-family: var(--fontFamily);
}

.login-main-layout {
    height: 50vh;
    display: flex;
}

.login-form-layout {
    width: 100%;
    height: 100vh;
    background: var(--colorBlueDarkBackground);
    color: white;
    display: inline-block;
    text-align: center;
}

.forgot-form-layout {
    width: 100%;
    height: 100vh;
    background: var(--colorBlueDarkBackground);
    color: white;
    display: inline-block;
    text-align: center;
}

.login-right-layout {
    width: 75%;
    height: 100vh;
    background: black;
    position: relative;
}

.login-container-img-logo {
    margin-top: 30%;
}

.login-container-img-background {
    width: 80%;
    height: 60%;
    position: absolute;
    padding-top: 8px;
    top: 43%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
}

.login-form-welcome-text {
    margin-top: 48px;
    font-weight: var(--fontBold);
}

.login-secondary-text {
    font-weight: var(--fontWeightSecundaryText);
    font-size: var(--fontSizeSecondaryText);
}

.login-secondary-bold-text {
    margin: 0 auto;
    width: 70%;
    font-size: var(--fontSizeSecondaryText);
    text-align: left;
}

.login-input-main-layout {
    margin: 4px auto 0;
    height: 4rem;
    width: 70%;
}

.login-wrapper-input {
    margin: 8px auto 0;
    height: 3rem;
    width: 97%;
    border-radius: var(--borderRadiusInput);
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    align-items: center;
    background-color: white;
}

.login-input {
    background-color: transparent;
    border: none;
    height: 100%;
    font-size: var(--fontSize);
    width: 100%;
    margin-right: 16px;
    outline: none;
}

.login-input-error {
    margin: 2px auto 0;
    width: 100%;
    height: 1rem;
    display: flex;
    align-items: center;
    color: var(--colorYellowFontError);
    font-size: var(--fontSizeError);
    font-family: var(--fontFamily);
}

.login-input-left-icon {
    position: absolute;
    cursor: none;
    align-items: center;
    display: flex;
}

.login-input-right-icon {
    position: absolute;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.login-button-type-label-forgot-password {
    margin: 0 auto 0 14%;
    width: auto;
    border: none;
    box-shadow: none;
    display: inline-flex;
    background-color: transparent;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    float: left;
    font-size: var(--fontSizeSecondaryText);
}

.login-container-remember-me {
    margin: 18px auto 0;
    width: 70%;
    display: inline-block;
}

.login-label-remember-me {
    margin-top: 2px;
    margin-left: 4px;
    float: left;
    display: inline-flex;
    font-size: var(--fontSizeSecondaryText);
}

.login-button {
    margin: 16px auto 0;
    height: 3rem;
    width: 70%;
    background: var(--colorBluePrimaryButton);
    color: white;
    border: none;
    border-radius: var(--borderRadiusInput);
    font-weight: var(--fontBold);
}

.forgot-button {
    margin: 16px auto 0;
    margin-bottom: 180px;
    height: 50px;
    width: 70%;
    text-decoration: underline;
    background-color:transparent; 
    border-radius: 11px;
    border: none; 
    box-shadow: none;
    color: white;
    border: none;

    border-radius: var(--borderRadiusInput);
    font-weight: var(--fontBold);

    cursor: pointer;    
    
}

.GoToLogin-button {
    margin: 16px auto 0;
    margin-bottom: 180px;
    height: 50px;
    width: 70%;
    text-decoration: underline;
    background-color:transparent; 
    border-radius: 11px;
    border: none; 
    box-shadow: none;
    color: white;
    border: none;

    border-radius: var(--borderRadiusInput);
    font-weight: var(--fontBold);

    cursor: pointer;    
    
}



.login-button:hover {
    background: var(--colorBlueAccentButton);
}

.login-button-type-label-unlock-access {
    border: none;
    margin: 16px auto;
    width: auto;
    box-shadow: none;
    display: inline-flex;
    background-color: transparent;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    font-size: var(--fontSizeSecondaryText);
}

.login-form-error-message {
    border: none;
    margin: 8px auto;
    width: auto;
    box-shadow: none;
    display: flow;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    color: var(--colorYellowFontError);
    font-size: var(--fontSizeError);
    font-family: var(--fontFamily);
}

.forms-container {
    height: 100vh;
    width: 25%;
    display: flex;
    flex-direction: column;
  }

.alert-box {
    background-color: #FFA07A;
    color: white;
    font-size: 20px;
    font-weight: bold;

    padding: 20px;
    margin: 10px 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}